import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";
import { signOut } from "firebase/auth";
import { updateDoc, doc } from "firebase/firestore";
import { AuthContext } from "../context/auth";
import { useHistory } from "react-router-dom";
import imagen from "../assets/LOGO_HIT_DATA-05.png";
const Navbar = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const handleSignOut = async () => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      isOnline: false,
    });
    await signOut(auth);
    history.replace("/login");
  };
  const inicio = (e)=>{
    e.preventDefault();
    history.replace("/");
  }
  return (
    <nav>
       <img
          src={imagen}
          style={{ height: "40px", width: "auto" }}
          onClick={inicio}
          alt="logo hit data"
        />
      <h3>
        
        <Link to="/">Chat Hit Data</Link>
      </h3>
      <div>
        {user ? (
          <>
            <Link to="/profile">Perfil</Link>
            <button className="btn-logout" onClick={handleSignOut}>
              {" "}
              Salir
            </button>
          </>
        ) : (
          <>
            <Link to="/register">Registro</Link>
            <Link to="login"> Iniciar sesión</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
